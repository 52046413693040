<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="balance"
                    :fields="fields"
                    @row-dblclicked="test"
                    head-row-variant="secondary"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(type)="data">
                        <div>
                            Пополнение
                        </div>
                    </template>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    data(){
        return{
            driver_id: this.$route.query.driver_id,
            balance: [],
            fields: [
            {key: 'checkbox', label: '', thStyle: {width: '30px'}},
            {key: 'id', label: 'ID', sortable: true, thStyle: {width: '40px'}},
            {key: 'login', label: 'Логин', sortable: true, thStyle: {width: '80px'}},
            {key: 'division', label: 'Подразделение', sortable: true, thStyle: {width: '150px'}},
            {key: 'type', label: 'Тип', sortable: true, thStyle: {width: '150px'}},
            {key: 'transaction_id', label: 'ID транзакции', sortable: true, thStyle: {width: '150px'}},
            {key: 'price', label: 'Сумма', sortable: true, thStyle: {width: '150px'}},
            {key: 'status', label: 'Статус', sortable: true, thStyle: {width: '150px'}},
            {key: 'provider', label: 'Провайдер', sortable: true, thStyle: {width: '150px'}},
            {key: 'date', label: 'Время перевода', sortable: true, thStyle: {width: '150px'}},
        ],
        }
    },
    methods:{
        test(){
            let division_id = JSON.parse(localStorage.getItem('userData'))
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        resizeable()
        this.$http
            .get(`report/replenishment?filter_performer_id=${this.driver_id}`)
            .then(res => {
                this.balance = res.data
                this.$store.commit('pageData/setdataCount', this.balance.length)
            })
        
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
</style> 